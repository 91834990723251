import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import GmbhDouble from './pages/Gmbh_double';
import GmbhSingle from './pages/Gmbh_single';
import AGDouble from "./pages/AG_double";
import AGSingle from "./pages/AG_single"
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
        
  components: {

      MuiFormControl: {
          styleOverrides: {
              root: {
                  width: "30ch",
                  
                  borderRadius: "10px"
                  
              }
          }
      },
              
      MuiDateTimepicker: {
        styleOverrides: {
          root:{
            width: "30ch"
          }
        }
      },
      

      MuiTextField: {
        styleOverrides: {
          root: {
              margin: "10px",
              m: 1, 
              width: '30ch',
              '& fieldset': {
                  //borderColor: '#fff',
              },

              "& .MuiSvgIcon-root": { color: "white" },

              '& label': {
                  color: '#fff', // label color
              },
              '& label.Mui-focused': {
                  color: '#fff', // focused small text color
              },
              '& .MuiInput-underline:after': {
                  borderBottomColor: '#000', // focused underline color
              },
              '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                  borderColor: '#fff', // focused outline color
              },
            },
          },
        },
      },
    },

  palette: {
      background: {
          default: '#090F20',
          paper: '#1C1D22',
          bigbox: "#1c2333",
          smallbox: "#242D42",
      },

      headingText: {
          primary: '#fff',
      },

      text: {
          primary: '#fff',
          secondary: '#fff',
      },
      
      primary: {
          main: '#fff',
      },

      good_response: {
          main: '#2DA71A',
      },
      
  }
});


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home theme={theme} />} />
        <Route path="/GmbH/2" element={<GmbhDouble theme={theme} />} />
        <Route path="/GmbH/1" element={<GmbhSingle theme={theme} />} />
        <Route path="/AG/2" element={<AGDouble theme={theme} />} />
        <Route path="/AG/1" element={<AGSingle theme={theme} />} />

        
      </Routes>
    </BrowserRouter>
  );
}

export default App;
