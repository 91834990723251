import React from "react";
import Box from "@mui/material/Box";

export default function Bigbox(props) {
    return(
        <Box
                component="form"
                sx={{
                    
                    backgroundColor: 'background.bigbox',
                    borderRadius: "5px",
                    margin: "30px",
                    padding: "10px",
                    boxShadow: 20,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: "330px",
                    
                }}
                
                noValidate
                autoComplete="off"
            >
                {props.children}
            </Box>
    )
}