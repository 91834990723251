import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Bigbox from "../components/Bigbox";
import Smallbox from "../components/Smallbox";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from "react-router-dom"
import Titlebox from "../components/Titlebox";

export default function Home(props) {
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={props.theme}>
      <CssBaseline/>
      <Bigbox>
        <Titlebox>
          <h1>Willkommen auf der interaktiven Voelkerlaw Website</h1>
        </Titlebox>
        <Smallbox>
          <h3 style={{textAlign: "center"}}>Gründungen</h3>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
          <Button
            sx={{
              maxWidth: "30%",
              margin: "5px"
            }}
            variant="outlined"
            onClick={() =>{
              navigate("/GmbH/1")
            }}
          >
            zur GmbH-Gründung mit einem Gründer
            <ArrowForwardIosIcon/>
          </Button>
          
          <Button
            sx={{
              maxWidth: "30%",
              margin: "5px"
            }}
            variant="outlined"
            onClick={() =>{
              navigate("/GmbH/2")
            }}
          >
            zur GmbH-Gründung mit zwei Gründern
            <ArrowForwardIosIcon/>
          </Button>
            <br/>
          <Button
            sx={{
              maxWidth: "30%",
              margin: "5px"
            }}
            variant="outlined"
            onClick={() =>{
              navigate("/AG/1")
            }}
          >
            zur AG-Gründung mit einem Gründer
            <ArrowForwardIosIcon/>
          </Button>

          <Button
            sx={{
              maxWidth: "30%",
              margin: "5px"
            }}
            variant="outlined"
            onClick={() =>{
              navigate("/AG/2")
            }}
          >
            zur AG-Gründung mit zwei Gründern
            <ArrowForwardIosIcon/>
          </Button>
          </div>
        </Smallbox>
      </Bigbox>
    </ThemeProvider>

  )
}