import React from "react";
import { IconButton } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from "react";

export default function Info(props) {
    const [open, setOpen] = useState(false)

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
            }}
        >
        <IconButton
            aria-label="show info"
            color="inherit"
            size="small"
            onClick={() =>{
                setOpen(true);
            }}
        >
            <InfoOutlinedIcon/>
        </IconButton>
        <Dialog
            open={open}
            scroll="paper"
            onClose={() => {
                setOpen(false);
            }}
        >
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent dividers={true}>
                <DialogContentText
                    tabIndex={-1}
                >
                    {props.text}
                </DialogContentText>
            </DialogContent>
        </Dialog>
        </div>
    )
}