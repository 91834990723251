import React from "react";
import Box from "@mui/material/Box";

export default function Smallbox(props) {
    return(
        <Box
            sx={{
                backgroundColor: 'background.smallbox',
                borderRadius: "10px",
                margin: "10px",
                padding: "5px",
                boxShadow: 20,
                width: "100%",
            }}
        >
            {props.children}
        </Box>
    )
}