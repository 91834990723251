import React from "react";
import Box from "@mui/material/Box";

export default function Titlebox(props) {
    return(
        <Box
            sx={{
                textAlign: "center",
                maxWidth: "110ch",
                border: "3px solid #fff",
                
                borderRadius: "10px",
                margin: "10px",
                paddingX: "30px",
                
                boxShadow: 20,
            }}
        >
            {props.children}
        </Box>
    )
}