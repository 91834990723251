import * as React from 'react';
import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Button } from '@mui/material';
import Bigbox from '../components/Bigbox';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Smallbox from '../components/Smallbox';
import { ThemeProvider } from '@mui/material/styles';
import "./Gmbh.css"
import CssBaseline from '@mui/material/CssBaseline';
import Titlebox from '../components/Titlebox';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import Snackbar from '@mui/material/Snackbar';
import Info from '../components/Info';

export default function Gmbh(props) {
    const [GmbhName, setGmbhName] = useState('');
    const [SitzKT, setSitzKT] = useState('');
    const [DatumTermin,  setDatumTermin] =  useState(null); // set default to Dayjs current date
    const [Gesellschaftszweck, setGesellschaftszweck] = useState('');
    const [NameGründer1, setNameGründer1] = useState('');
    const [VornameGründer1, setVornameGründer1] = useState('');
    const [GeburtsdatumGründer1, setGeburtsdatumGründer1] = useState(null);
    const [GeschlechtGründer1, setGeschlechtGründer1] = useState('');
    const [BürgerortGründer1, setBürgerortGründer1] = useState('');
    const [AdresseGründer1, setAdresseGründer1] = useState('');
    
    const [SitzAdresse, setSitzAdresse] = useState('');
    const [AusweisNummerGründer1, setAusweisNummerGründer1] = useState('');
    const [AusweisartGründer1, setAusweisartGründer1] = useState('');
    
    const [AnzahlStammanteileGründer1, setAnzahlStammanteileGründer1] = useState(200);
    
    const [NameAdresseBank, setNameAdresseBank] = useState('');
    const [DatumKapitalEinzahlungsbestätigung, setDatumKapitalEinzahlungsbestätigung] = useState(null);
    const [LandGründer1, setLandGründer1] = useState('');
    
    const [Sitz, setSitz] = useState('');
    const [KTBürgerortGründer1,  setKTBürgerortGründer1] = useState('');
    
    const [Gesellschaftskapital, setGesellschaftskapital] = useState(20000);
    const [Nennwert, setNennwert] = useState(100);

    const [StammanteileError, setStammanteileError] = useState(false);
    const [buttonColor, setButtonColor] = useState("primary");
    const [renderAlert, setRenderAlert] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState("success")
    const [alertText, setAlertText] = useState("")
    const [Loading, setLoading] = useState(false)
    const [allFilledOut, setAllFilledOut] = useState(false);
    // const [Jahr, setJahr] = useState();
    

    

    useEffect(() => {
        if ((AnzahlStammanteileGründer1) * Nennwert === Gesellschaftskapital && Gesellschaftskapital >= 20000) {
            setStammanteileError(false);
        } else {
            setStammanteileError(true);
        }
        
    }, [AnzahlStammanteileGründer1, Gesellschaftskapital, Nennwert])

    useEffect(() => {
        if (
            GmbhName !== "" &&
            SitzKT  !== "" &&
            DatumTermin !== null &&
            Gesellschaftszweck !== "" &&
            NameGründer1 !== "" &&
            VornameGründer1 !== "" &&
            GeburtsdatumGründer1 !== null &&
            GeschlechtGründer1 !== "" &&
            BürgerortGründer1 !== "" &&
            AdresseGründer1 !== "" &&
            
            SitzAdresse !== "" &&
            AusweisNummerGründer1 !== "" &&
            AusweisartGründer1 !== "" &&
            
            AnzahlStammanteileGründer1 !== "" &&
            
            NameAdresseBank !== "" &&
            DatumKapitalEinzahlungsbestätigung !== null &&
            LandGründer1 !== "" &&
            
            Sitz !== "" &&
            KTBürgerortGründer1 !== "" &&
            
            Gesellschaftskapital !== "" &&
            Nennwert !== ""
        ) {
            setAllFilledOut(true)
        } else {
            setAllFilledOut(false)
        }
        
    }, [GmbhName, 
    SitzKT , 
    DatumTermin, 
    Gesellschaftszweck, 
    NameGründer1, 
    VornameGründer1, 
    GeburtsdatumGründer1, 
    GeschlechtGründer1, 
    BürgerortGründer1, 
    AdresseGründer1, 
    
    SitzAdresse, 
    AusweisNummerGründer1, 
    AusweisartGründer1, 
    
    AnzahlStammanteileGründer1, 
     
    NameAdresseBank, 
    DatumKapitalEinzahlungsbestätigung, 
    LandGründer1, 
    
    Sitz, 
    KTBürgerortGründer1, 
    
    Gesellschaftskapital, 
    Nennwert])
    

    
    function handleSubmit() {
        // fetch api post request with all data
        setLoading(true)
        fetch(process.env.REACT_APP_API_URL + '/GmbH/1/submitGmbH', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                NAME_GMBH: GmbhName,
                SITZ_KT: SitzKT,
                DATUM_TERMIN: DatumTermin.toISOString(),
                GESELLSCHAFTSZWECK: Gesellschaftszweck,
                NAME_GRÜNDER1: NameGründer1,
                VORNAMEN_GRÜNDER1: VornameGründer1,
                GEBURTSDATUM_GRÜNDER1: GeburtsdatumGründer1.toISOString(),
                GESCHLECHT_GRÜNDER1: GeschlechtGründer1,
                BÜRGERORT_GRÜNDER1: BürgerortGründer1,
                ADRESSE_GRÜNDER1: AdresseGründer1,
                
                SITZADRESSE: SitzAdresse,
                AUSWEISNUMMER_GRÜNDER1: AusweisNummerGründer1,
                AUSWEISART_GRÜNDER1: AusweisartGründer1,
                
                ANZAHL_STAMMANTEILE_GRÜNDER1: AnzahlStammanteileGründer1,
                
                NAME_ADRESSE_BANK: NameAdresseBank,
                DATUM_KAPITALEINZAHLUNGSBESTÄTIGUNG: DatumKapitalEinzahlungsbestätigung.toISOString(),
                LAND_GRÜNDER1: LandGründer1,
                
                SITZ: Sitz,
                KT_BÜRGERORT_GRÜNDER1: KTBürgerortGründer1,
                
                GESELLSCHAFTSKAPITAL: Gesellschaftskapital,
                NENNWERT:  Nennwert,
                //JAHR: Jahr
        })
        })
        .then(response => response.json())
        .then(data => {
            setLoading(false)
            
            if (data.status_code === 201) {
                console.log('success');
                setAlertText("Daten wurden erfolgreich abgesendet")
                setButtonColor("success");
                setAlertSeverity("success")
                setRenderAlert(true);
            } else {
                console.log('server responded error');
                setButtonColor("error");
                setAlertText("Daten beinhalten Fehler")
                setAlertSeverity("error")
                setRenderAlert(true);

            }
            }
        )
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    
    return (
        <ThemeProvider theme={props.theme}>
            <CssBaseline />
            <Bigbox>
                
                    <Titlebox
                        sx={{
                            
                            maxWidth: "50ch",
                            border: "3px solid #fff",
                            
                            borderRadius: "10px",
                            margin: "10px",
                            padding: "0px",
                            boxShadow: 20,
                        }}
                    >
                        <h1 style={{textAlign: 'center', marginTop: "6px", marginBottom: "6px",}}>Formular zur Gründung einer GmbH</h1>
                    </Titlebox>
                    <Smallbox>
                        <h3 style={{textAlign: 'center'}}>Angaben zur GmbH</h3>
                        
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap"
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                            >
                            <TextField
                                required
                                label="GmbH Name"
                                value={GmbhName}
                                onChange={(e) => setGmbhName(e.target.value)}
                                variant="filled"
                            />
                            <Info
                                title="GmbH Name"
                                text="Beispiel: Muster GmbH"
                            />
                            </div>
                            
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row"
                                }}
                            >
                            <TextField
                                required
                                label="Sitzadresse"
                                value={SitzAdresse}
                                onChange={(e) => setSitzAdresse(e.target.value)}
                                variant="filled"
                            
                            />
                            <Info
                                title="Sitzadresse"
                                text="Beispiel: Astrasse 10, 6300 Zug"
                            />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row"
                                }}
                            >
                                <TextField
                                    required
                                    label="Sitzort"
                                    value={Sitz}
                                    onChange={(e) => setSitz(e.target.value)}
                                    variant="filled"
                            
                                />
                                <Info
                                    title="Sitzort"
                                    text="Beispiel: Zug"
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row"
                                }}
                            >
                                <FormControl sx={{ m: 1,  }}>
                                    <InputLabel>Kanton des Sitzes</InputLabel>
                                    <Select
                            
                                        required
                                        label="Kanton des Sitzes"
                                        value={SitzKT}
                                        onChange={(e) => setSitzKT(e.target.value)}
                                    >
                                        <MenuItem value="AG">AG</MenuItem>
                                        <MenuItem value="AI">AI</MenuItem>
                                        <MenuItem value="AR">AR</MenuItem>
                                        <MenuItem value="BE">BE</MenuItem>
                                        <MenuItem value="BL">BL</MenuItem>
                                        <MenuItem value="BS">BS</MenuItem>
                                        <MenuItem value="FR">FR</MenuItem>
                                        <MenuItem value="GE">GE</MenuItem>
                                        <MenuItem value="GL">GL</MenuItem>
                                        <MenuItem value="GR">GR</MenuItem>
                                        <MenuItem value="JU">JU</MenuItem>
                                        <MenuItem value="LU">LU</MenuItem>
                                        <MenuItem value="NE">NE</MenuItem>
                                        <MenuItem value="NW">NW</MenuItem>
                                        <MenuItem value="OW">OW</MenuItem>
                                        <MenuItem value="SG">SG</MenuItem>
                                        <MenuItem value="SH">SH</MenuItem>
                                        <MenuItem value="SO">SO</MenuItem>
                                        <MenuItem value="SZ">SZ</MenuItem>
                                        <MenuItem value="TG">TG</MenuItem>
                                        <MenuItem value="TI">TI</MenuItem>
                                        <MenuItem value="UR">UR</MenuItem>
                                        <MenuItem value="VD">VD</MenuItem>
                                        <MenuItem value="VS">VS</MenuItem>
                                        <MenuItem value="ZG">ZG</MenuItem>
                                        <MenuItem value="ZH">ZH</MenuItem>
                                    </Select>
                                </FormControl>
                                <Info
                                    title="Kanton des Sitzes"
                                    text="Beispiel: ZG"
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row"
                                }}
                            >
                                <TextField
                                    multiline
                                    //rows={4}
                                    required
                                    label="Gesellschaftszweck"
                                    value={Gesellschaftszweck}
                                    onChange={(e) => setGesellschaftszweck(e.target.value)}
                                    variant="filled"
                            
                                />
                                <Info
                                    title="Gesellschaftszweck"
                                    text={<>Beispiel: Herstellung von und Handel mit Sportgeräten<br/><br/>Der Zweck enthält automatisch zusätzlich: Die Gesellschaft kann Zweigniederlassungen und Tochtergesellschaften im In- und Ausland errichten sowie Patente, Handelsmarken, Domainnamen und technisches und industrielles Know-how erwerben, verwalten und übertragen. 
                            Die Gesellschaft kann alle finanziellen, kommerziellen und anderen Tätigkeiten ausüben, welche mit ihrem Zweck direkt oder indirekt in Zusammenhang stehen, so etwa sich im In- und Ausland an Unternehmen beteiligen sowie Liegenschaft erwerben, verwalten und veräussern. Die Besorgung von eigentlichen Bankgeschäften und Vermögensverwaltungen ist ausgeschlossen.</>}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row"
                                }}
                            >
                                <TextField
                                    required
                                    label="Name und Adresse der Bank"
                                    value={NameAdresseBank}
                                    onChange={(e) => setNameAdresseBank(e.target.value)}
                                    variant="filled"
                                
                                />
                                <Info
                                    title="Name und Adresse der Bank"
                                    text="Beispiel: Zürcher Kantonalbank, Paradeplatz 1, 8001 Zürich"
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row"
                                }}
                            >
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                
                                        required
                                        ampmInClock = {false}
                                        ampm = {false}
                                        label="Termin mit Uhrzeit"
                                        value={DatumTermin}
                                        format='DD-MM-YYYY HH:mm'
                                        dateAdapter={AdapterDayjs}
                                        onChange={(newValue) => {
                                                setDatumTermin(newValue);
                                            }
                                        }
                                    />
                                </LocalizationProvider>
                                <Info
                                    title="Termin mit Uhrzeit"
                                    text="Beispiel: 29-02-2023 16:30"
                                />
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row"
                                }}
                            >
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        required
                                        label="Kapitaleinzahlungsbest."
                                        value={DatumKapitalEinzahlungsbestätigung}
                                        format='DD-MM-YYYY'
                                        dateAdapter={AdapterDayjs}
                                        onChange={(newValue) => {
                                                setDatumKapitalEinzahlungsbestätigung(newValue);
                                            }
                                        }
                                    />
                                </LocalizationProvider>
                                <Info
                                    title="Kapitaleinzahlungsbestätigung"
                                    text="Beispiel: 27-02-2023"
                                />
                            </div>
                            
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row"
                                }}
                            >
                                <TextField
                                    error={StammanteileError}
                                    required
                                    label="Gesellschaftskapital in CHF"
                                    type='number'
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*'}}
                                    value={Gesellschaftskapital}
                                    onChange={(e) => setGesellschaftskapital(parseInt(e.target.value))}
                                    variant="filled"
                                
                                />
                                <Info
                                    title="Gesellschaftskapital in CHF"
                                    text={<>Beispiel: 20000 CHF<br/><br/>Diese Gleichung muss erfüllt sein: Stammanteile_Gründer1 * Nennwert = Gesellschaftskapital</>}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row"
                                }}
                            >
                                <TextField
                                    error={StammanteileError}
                                    required
                                    label="Nennwert in CHF"
                                    type='number'
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*'}}
                                    value={Nennwert}
                                    onChange={(e) => setNennwert(parseInt(e.target.value))}
                                    variant="filled"
                                
                                />
                                <Info
                                    title="Nennwert in CHF"
                                    text={<>Beispiel: 100 CHF<br/><br/>Diese Gleichung muss erfüllt sein: Stammanteile_Gründer1 * Nennwert = Gesellschaftskapital</>}
                                />
                            </div>
                        </div>

                    </Smallbox>


                    <Smallbox>
                    <h3 style={{textAlign: 'center'}}>Angaben des Gründers</h3>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row"
                            }}
                        >
                            <TextField
                                required
                                label="Name"
                                value={NameGründer1}
                                onChange={(e) => setNameGründer1(e.target.value)}
                                variant="filled"
                            />
                            <Info
                                title="Name"
                                text="Beispiel: Müller"
                            />
                        </div>

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row"
                            }}
                        >
                            <TextField
                                required
                                label="Vornamen"
                                value={VornameGründer1}
                                onChange={(e) => setVornameGründer1(e.target.value)}
                                variant="filled"
                            />
                            <Info
                                title="Vornamen"
                                text="Beispiel: Hans Tobias"
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row"
                            }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Geburtsdatum"
                                    value={GeburtsdatumGründer1}
                                    format='DD-MM-YYYY'
                                    dateAdapter={AdapterDayjs}
                                    onChange={(newValue) => {
                                            setGeburtsdatumGründer1(newValue);
                                        }
                                    }
                                />
                            </LocalizationProvider>
                            <Info
                                title="Geburtsdatum"
                                text="Beispiel: 01-01-2000"
                            />
                        </div>
                    
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row"
                            }}
                        >
                            <TextField
                                required
                                label="Bürgerort"
                                value={BürgerortGründer1}
                                onChange={(e) => setBürgerortGründer1(e.target.value)}
                                variant="filled"
                            />
                            <Info
                                title="Bürgerort"
                                text="Beispiel: Thun"
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row"
                            }}
                        >
                            <FormControl sx={{ m: 1, }}>
                                <InputLabel>Kanton des Bürgerortes</InputLabel>
                                <Select
                                    required
                                    label="Kanton des Bürgerortes"
                                    value={KTBürgerortGründer1}
                                    onChange={(e) => setKTBürgerortGründer1(e.target.value)}
                                >
                                    <MenuItem value="AG">AG</MenuItem>
                                    <MenuItem value="AI">AI</MenuItem>
                                    <MenuItem value="AR">AR</MenuItem>
                                    <MenuItem value="BE">BE</MenuItem>
                                    <MenuItem value="BL">BL</MenuItem>
                                    <MenuItem value="BS">BS</MenuItem>
                                    <MenuItem value="FR">FR</MenuItem>
                                    <MenuItem value="GE">GE</MenuItem>
                                    <MenuItem value="GL">GL</MenuItem>
                                    <MenuItem value="GR">GR</MenuItem>
                                    <MenuItem value="JU">JU</MenuItem>
                                    <MenuItem value="LU">LU</MenuItem>
                                    <MenuItem value="NE">NE</MenuItem>
                                    <MenuItem value="NW">NW</MenuItem>
                                    <MenuItem value="OW">OW</MenuItem>
                                    <MenuItem value="SG">SG</MenuItem>
                                    <MenuItem value="SH">SH</MenuItem>
                                    <MenuItem value="SO">SO</MenuItem>
                                    <MenuItem value="SZ">SZ</MenuItem>
                                    <MenuItem value="TG">TG</MenuItem>
                                    <MenuItem value="TI">TI</MenuItem>
                                    <MenuItem value="UR">UR</MenuItem>
                                    <MenuItem value="VD">VD</MenuItem>
                                    <MenuItem value="VS">VS</MenuItem>
                                    <MenuItem value="ZG">ZG</MenuItem>
                                    <MenuItem value="ZH">ZH</MenuItem>
                                </Select>
                            </FormControl>
                            <Info
                                title="Kanton des Bürgerortes"
                                text="Beispiel: BE"
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row"
                            }}
                        >
                            <TextField
                                required
                                label="Land"
                                value={LandGründer1}
                                onChange={(e) => setLandGründer1(e.target.value)}
                                variant="filled"
                            />
                            <Info
                                title="Land"
                                text="Beispiel: NL"
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row"
                            }}
                        >
                            <TextField
                                required
                                label="Adresse"
                                value={AdresseGründer1}
                                onChange={(e) => setAdresseGründer1(e.target.value)}
                                variant="filled"
                            />
                            <Info
                                title="Adresse"
                                text="Beispiel: Mustergasse 7, 3000 Bern"
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row"
                            }}
                        >
                            <FormControl sx={{ m: 1, }}>
                                <InputLabel>Geschlecht</InputLabel>
                                <Select
                                    required
                                    label="Geschlecht"
                                    value={GeschlechtGründer1}
                                    onChange={(e) => setGeschlechtGründer1(e.target.value)}
                                >
                                    <MenuItem value="männlich">männlich</MenuItem>
                                    <MenuItem value="weiblich">weiblich</MenuItem>
                                </Select>
                            </FormControl>
                            <Info
                                title="Geschlecht"
                                text="Beispiel: männlich"
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row"
                            }}
                        >
                            <FormControl sx={{ m: 1, }}>
                                <InputLabel>Ausweisart</InputLabel>
                                <Select
                                    required
                                    label="Ausweisart"
                                    value={AusweisartGründer1}
                                    onChange={(e) => setAusweisartGründer1(e.target.value)}
                                >
                                    <MenuItem value="ID">ID</MenuItem>
                                    <MenuItem value="Pass">Pass</MenuItem>
                                </Select>
                            </FormControl>
                            <Info
                                title="Ausweisart"
                                text="Beispiel: ID"
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row"
                            }}
                        >
                            <TextField
                                required
                                label="Ausweisnummer"
                                value={AusweisNummerGründer1}
                                onChange={(e) => setAusweisNummerGründer1(e.target.value)}
                                variant="filled"
                            />
                            <Info
                                title="Ausweisnummer"
                                text="Beispiel: X1234567"
                            />
                        </div>
                    
                    
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row"
                            }}
                        >
                            <TextField
                                error={StammanteileError}
                                required
                                label="Anzahl Stammanteile"
                                type='number'
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*'}}
                                value={AnzahlStammanteileGründer1}
                                onChange={(e) => setAnzahlStammanteileGründer1(parseInt(e.target.value))}
                                variant="filled"
                            />
                            <Info
                                title="Anzahl Stammanteile"
                                text={<>Beispiel: 100<br/><br/>Diese Gleichung muss erfüllt sein: Stammanteile_Gründer1 * Nennwert = Gesellschaftskapital</>}
                            />
                        </div>
                    </div>

                    </Smallbox>
                    
                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "row"}}>
                        <div style={{display: 'flex', justifyContent: 'center', flexDirection: "column", width: "100%"}}>
                            
                            <Button disabled={!allFilledOut || Loading} color={buttonColor} sx={{maxWidth: "15ch"}} variant="contained" onClick={handleSubmit}>Absenden</Button>
                            
                        </div>
                        { Loading &&
                        <Fade
                            in={Loading}
                        >
                        <CircularProgress/>
                        
                        </Fade>
                        }
                    </div>
                    
                
            </Bigbox>
            
        <Snackbar
            open={renderAlert}
            anchorOrigin={{ vertical: "bottom", horizontal: "center"}}
        >
            <Alert
                sx={{
                    width: "60%"
                }}
                severity={alertSeverity}
                onClose={(event, reason) => {
                    if (reason === 'clickaway') {
                      return;
                    }
                    setRenderAlert(false);
                  }}
            >{alertText}</Alert>
        </Snackbar>
        
        </ThemeProvider>
    );
    
}